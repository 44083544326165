import { type ReactNode } from "react";
import { useAtomValue } from "jotai";

import { isAuthenticatedVar } from "@/variables/globalVar";

import { ChannelProvider } from "shared/channel";
import { ApiProvider } from "./ApiProvider";

const channelUrl = import.meta.env.VITE_REACT_APP_CHANNEL_ENDPOINT;

export interface ProvidersProps {
  children: ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedVar);

  return (
    <ApiProvider>
      <ChannelProvider
        channelUrl={channelUrl}
        isAuthenticated={isAuthenticated}
      >
        {children}
      </ChannelProvider>
    </ApiProvider>
  );
}
